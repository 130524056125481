import type { CheckFileLabel } from '~/entities/checkFileLabel'
import type { CheckFile, CheckFileWithLabels } from '~/entities/checkFile'
import type { CheckFileLabelAssignment } from '~/entities/checkFileLabelAssignment'
import type { SortOptions } from '~/entities/supabase'

export const getCheckFiles = async (
  locationId: number,
  checkFileLabelIds: CheckFileLabel['id'][] = [],
  sortOptions: SortOptions<CheckFile> = [
    { column: 'title', order: 'asc' },
    { column: 'id', order: 'asc' },
  ]
): Promise<CheckFileWithLabels[]> => {
  const query = useSupabaseClient()
    .from('check_files')
    .select('*, check_file_labels(*)')
    .eq('location_id', locationId)
    .eq('status', 'PUBLIC')
  if (checkFileLabelIds && checkFileLabelIds.length > 0) {
    const ids = await getCheckFileIdsByLabelIds(checkFileLabelIds)
    query.in('id', ids)
  }
  for (const { column, order } of sortOptions) {
    query.order(column, { ascending: order === 'asc' })
  }
  const { data, error } = await query
  if (error) throw error
  return data
}

export const getCheckFileIdsByLabelIds = async (
  checkFileLabelIds: CheckFileLabel['id'][]
): Promise<CheckFile['id'][]> => {
  const { data, error } = await useSupabaseClient()
    .from('check_file_label_assignments')
    .select('check_file_id')
    .in('check_file_label_id', checkFileLabelIds)
  if (error) throw error

  const countMap: Map<CheckFileLabelAssignment['check_file_id'], number> = new Map()
  data.forEach((item) => {
    const count = countMap.get(item.check_file_id) || 0
    countMap.set(item.check_file_id, count + 1)
  })

  return Array.from(countMap.entries())
    .filter(([, count]) => count === checkFileLabelIds.length)
    .map(([checkSheetId]) => checkSheetId)
}

export const getDailyCheckFile = async (checkFileId: CheckFile['id']) => {
  const { data, error } = await useSupabaseClient()
    .from('check_files')
    .select(
      '*, daily_verifiers(*, users!inner(*)), check_sheet_assignments!inner(*, check_sheets!inner(*, check_items!inner(*, frequency_settings!inner(*), timeboxes(*), number_conditions(*), images:example_images(*, images!inner(*)))))'
    )
    .eq('id', checkFileId)
    .eq('check_sheet_assignments.check_sheets.check_items.frequency_settings.frequency', 'DAILY') // 毎回だけ
    .maybeSingle()
  if (error) throw error
  return data
}

export const getCheckFile = async (checkFileId: CheckFile['id']) => {
  const { data, error } = await useSupabaseClient()
    .from('check_files')
    .select('*')
    .eq('id', checkFileId)
    .maybeSingle()
  if (error) throw error
  return data
}
