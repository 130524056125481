import { createI18n } from 'vue-i18n'
import ja from '~/locales/ja.json'
import en from '~/locales/en.json'
import th from '~/locales/th.json'
import vi from '~/locales/vi.json'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: useLocale().currentLocale.value,
  fallbackLocale: 'en',
  messages: {
    ja,
    en,
    th,
    vi,
  },
  datetimeFormats: {
    // https://vue-i18n.intlify.dev/guide/essentials/datetime
    // {{ $d(new Date(), 'short') }} で yyyy/MM/dd が出力可能に
    ja: {
      short: { year: 'numeric', month: 'short', day: 'numeric' },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      year: { year: 'numeric' },
      yearMonth: { year: 'numeric', month: 'short' },
      month: { month: 'short' },
      monthDay: { month: 'short', day: '2-digit' },
      day: { day: '2-digit' },
      dateWithWeekday: { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' },
      weekday: { weekday: 'short' },
      dateTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    en: {
      short: { year: 'numeric', month: 'short', day: 'numeric' },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      year: { year: 'numeric' },
      yearMonth: { year: 'numeric', month: 'short' },
      month: { month: 'short' },
      monthDay: { month: 'short', day: '2-digit' },
      day: { day: '2-digit' },
      dateWithWeekday: { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' },
      weekday: { weekday: 'short' },
      dateTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    th: {
      short: { year: 'numeric', month: 'short', day: 'numeric' },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      year: { year: 'numeric' },
      yearMonth: { year: 'numeric', month: 'short' },
      month: { month: 'short' },
      monthDay: { month: 'short', day: '2-digit' },
      day: { day: '2-digit' },
      dateWithWeekday: { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' },
      weekday: { weekday: 'short' },
      dateTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    vi: {
      short: { year: 'numeric', month: 'short', day: 'numeric' },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      year: { year: 'numeric' },
      yearMonth: { year: 'numeric', month: 'short' },
      month: { month: 'short' },
      monthDay: { month: 'short', day: '2-digit' },
      day: { day: '2-digit' },
      dateWithWeekday: { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' },
      weekday: { weekday: 'short' },
      dateTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
})

export default i18n
