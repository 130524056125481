import type { LocationAccount, LocationAccountWithInfo } from '~/entities/locationAccount'

export const getLocationAccountEmail = async (
  organizationName: string,
  accountName: string
): Promise<LocationAccount['email'] | null> => {
  const { data, error } = await useSupabaseClient().functions.invoke(
    'retrieve-location-account-email',
    {
      body: {
        accountName,
        organizationName,
      },
    }
  )
  if (error) throw error
  return data?.email ?? null
}

export const getLocationAccountById = async (id: string): Promise<LocationAccountWithInfo> => {
  const { data, error } = await useSupabaseClient()
    .from('location_accounts')
    .select('*, organizations!inner(*), locations!inner(*)')
    .eq('id', id)
    .single()
  if (error) throw error
  if (!data.organizations || !data.locations) throw new Error('No data returned')
  return {
    ...data,
    organization: data.organizations,
    location: data.locations,
  }
}
