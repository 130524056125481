import type { LocationAccountWithInfo } from '~/entities/locationAccount'
import { getLocationAccountById } from '~/repositories/locationAccountRepository'

const recoverCurrentLocationAccount =
  (currentLocationAccount: Ref<LocationAccountWithInfo | null>) => async () => {
    const supabaseUser = useSupabaseUser()
    if (!currentLocationAccount.value && supabaseUser.value) {
      const data = await getLocationAccountById(supabaseUser.value.id)
      currentLocationAccount.value = data
    }
  }

export const useCurrentLocationAccount = () => {
  const currentLocationAccount = useState<LocationAccountWithInfo | null>('currentLocationAccount')
  return {
    currentLocationAccount,
    recoverCurrentLocationAccount: recoverCurrentLocationAccount(currentLocationAccount),
  }
}
