import dayjs, { locale } from 'dayjs'
import 'dayjs/locale/ja'
import 'dayjs/locale/th'
import 'dayjs/locale/vi'

const { currentLocale } = useLocale()
// TODO: フォーマット日付はリアクティブにはなっていないので、リロードしないと反映されない
locale(currentLocale.value)

export type Dayjs = dayjs.Dayjs

type DateLike = Date | Dayjs | string | dayjs.Dayjs

export function parseDate(date: DateLike | undefined) {
  return dayjs(date)
}

export function currentDate(): Dayjs {
  return parseDate(new Date())
}

export const currentBusinessDate = () => {
  // 夜勤を考慮して午前4時を1日の始まりとする
  const businessHourOffset = 4 // TODO: 拠点から取得するに変更予定
  return currentDate().subtract(businessHourOffset, 'hour')
}

export const getDatesInMonth = (year: number, month: number): Array<Dayjs> => {
  // dayjsのmonthは0origin
  const yyyymm = dayjs()
    .year(year)
    .month(month - 1)
  const daysInMonth = yyyymm.daysInMonth()
  return Array.from(Array(daysInMonth).keys()).map((day) => {
    return yyyymm.clone().date(day + 1)
  })
}

export const formatDate = (date: DateLike) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatDateWithWeekday = (date: DateLike) => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DD(ddd)')
}

export const formatDateTime = (date: DateLike) => {
  if (!date) return ''
  return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
}

export const formatMMDD = (date: DateLike) => {
  return dayjs(date).format('MM/DD')
}

export const formatMMDDWithWeekday = (date: DateLike) => {
  return dayjs(date).format('MM/DD(ddd)')
}

export const formatYYYYMM = (date: DateLike) => {
  return dayjs(date).format('YYYY-MM')
}

export const formatDateTimeWithWeekday = (date: DateLike) => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DD(ddd) HH:mm')
}

export const formatYYYY = (date: Dayjs): string => {
  return date.format('YYYY')
}

export const formatMM = (date: Dayjs): string => {
  return date.format('MM')
}
