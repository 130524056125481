import type { User } from '~/entities/user'

const serialize = (value: any): string => {
  return JSON.stringify(value)
}

const deserialize = <T>(string: string | null): T | null => {
  if (!string) return null
  // FIXME: as Tの危険性ありだが型検証結構面倒…
  return JSON.parse(string) as T
}

const SESSION_STORAGE_KEY_USER = 'currentUser'

const getCurrentUser = (): User | null => {
  return deserialize<User>(sessionStorage.getItem(SESSION_STORAGE_KEY_USER))
}

const setCurrentUser = (user: User | null) => {
  if (!user) {
    sessionStorage.removeItem(SESSION_STORAGE_KEY_USER)
  } else {
    sessionStorage.setItem(SESSION_STORAGE_KEY_USER, serialize(user))
  }
}

const clear = () => {
  sessionStorage.clear()
}

export const useSessionStorage = () => {
  return {
    getCurrentUser,
    setCurrentUser,
    clear,
  }
}
