<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { NuxtError } from '#app'

useHead({
  titleTemplate: 'Checkup!',
})

const props = defineProps<{ error: NuxtError }>()

const { t } = useI18n()

const isChunkError = computed(() => {
  return props.error.message.includes('Failed to fetch dynamically imported module')
})

const errorMessage = computed(() => {
  if (props.error.statusCode === 404) {
    return t('ページが見つかりませんでした。')
  }
  return t('エラーが発生しました。')
})
</script>

<template>
  <div>
    <div class="error-page">
      <!-- [NOTE]
      デプロイのたびにchunkのハッシュが変わるため、ロード済みのmanifestではdynamic importに失敗しエラーページが表示されてしまう。
      エラーが発生しても組み込みのpluginによってハードリロードされ新しいchunkが読み込まれて問題ないケースであるためエラーメッセージは表示しないようにする。
      しかしデプロイ起因以外の同エラーが発生する可能性も考えて、戻るリンクの表示は残す。
      https://nuxt.com/docs/getting-started/error-handling#errors-with-js-chunks -->
      <h1 v-if="!isChunkError">{{ errorMessage }}</h1>
      <NuxtLink :to="{ name: 'index' }" class="m-2">{{ $t('TOPページに戻る') }}</NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
