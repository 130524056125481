export class LimitExceededError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'LimitExceededError'
  }
}

export class InvalidStatusError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'InvalidStatusError'
  }
}
