export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { recheckSession } = useAuth()
  await recheckSession()

  const supabaseUser = useSupabaseUser()

  // トップページであればリダイレクト先の指定はなくてOK
  const redirectTo = to.fullPath === '/' ? undefined : to.fullPath

  // 未ログイン状態の場合はlogin画面に転送
  if (!supabaseUser.value && to.name !== 'login') {
    // 未ログインのためsession storageを初期化
    const { clear } = useSessionStorage()
    clear()

    return navigateTo({ name: 'login', query: { redirect: redirectTo } })
  }

  // ログイン状態の場合
  if (supabaseUser.value) {
    // その他stateを必要に応じて復元
    const { currentLocationAccount, recoverCurrentLocationAccount } = useCurrentLocationAccount()
    const { currentOperator } = useCurrentOperator()
    const { currentTargetDate, resetCurrentTargetDate } = useCurrentTargetDate()

    if (!currentLocationAccount.value) {
      await recoverCurrentLocationAccount()
    }

    if (to.name === 'organizationId-operators-select') {
      // リダイレクトする必要がないページはそのまま
      return
    }

    // これ以降のリダイレクトは、状態によって遷移したらまずいケースをケアするためのもの
    if (!currentOperator.value) {
      return navigateTo({
        name: 'organizationId-operators-select',
        params: { organizationId: currentLocationAccount.value?.organization.id },
        query: { redirect: redirectTo },
      })
    }
    if (to.name?.toString().startsWith('organizationId-checkFile-checkFileId')) {
      const { getCheckFile } = useCheckFile()
      const { checkFileId } = to.params
      const checkFile = await getCheckFile(Number(checkFileId))
      if (!checkFile) {
        return navigateTo({
          name: 'organizationId-checkFiles-select',
          params: { organizationId: currentLocationAccount.value?.organization.id },
          query: { redirect: redirectTo },
        })
      }
    }
    if (to.name?.toString() === 'index' || to.name?.toString() === 'login') {
      return navigateTo({
        name: 'organizationId-checkFiles-select',
        params: { organizationId: currentLocationAccount.value?.organization.id },
        query: { redirect: redirectTo },
      })
    }
    if (to.name?.toString().startsWith('organizationId-checkFiles-checkFileId-yyyymmdd')) {
      currentTargetDate.value = to.params.yyyymmdd.toString()
    }
    if (to.name?.toString().startsWith('organizationId-checkFiles-checkFileId')) {
      const targetDate = parseDate(currentTargetDate.value ?? currentBusinessDate())
      const currentDate = currentBusinessDate()
      if (!targetDate.isSame(currentDate, 'day')) {
        // 日付またぎのタイミングで currentTargetDate をリセット
        resetCurrentTargetDate()
        // ページの日付と currentDate が異なる場合、チェックファイル選択画面へリダイレクト
        return navigateTo({
          name: 'organizationId-checkFiles-select',
          params: { organizationId: currentLocationAccount.value?.organization.id },
        })
      }
    }
  }
})
