import revive_payload_client_JpvmlIJzTh from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mKT9wzNlDp from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wXd3lJUkH3 from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OmLMSATUDB from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ts5PccLMYO from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DrOJP7JERt from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PGYvGb30Bp from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/operator/.nuxt/components.plugin.mjs";
import prefetch_client_zkfceqvVss from "/home/runner/work/checkup/checkup/operator/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.9_encoding@0.1.13_eslint@8.57.1_ioredis@5_spelx32gisvc5ejcij2nqkc5iy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/operator/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/operator/src/plugins/sentry.client.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/operator/src/plugins/i18n.ts";
export default [
  revive_payload_client_JpvmlIJzTh,
  unhead_mKT9wzNlDp,
  router_wXd3lJUkH3,
  payload_client_OmLMSATUDB,
  navigation_repaint_client_Ts5PccLMYO,
  check_outdated_build_client_DrOJP7JERt,
  chunk_reload_client_PGYvGb30Bp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zkfceqvVss,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  i18n_YCWKwfVXF6
]