import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { dsn, environment, debug } = config.public.sentry

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    environment,
    debug,
  })
})
