import { default as indexb7OvZt53SXMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/index.vue?macro=true";
import { default as indexBbeoKfJI7DMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/index.vue?macro=true";
import { default as index1uZbuNYQseMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue?macro=true";
import { default as indexPjqSDR95cYMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/todo/index.vue?macro=true";
import { default as indexy3B7uWtsqtMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/[ticketId]/index.vue?macro=true";
import { default as index1sHnDK8g1NMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/index.vue?macro=true";
import { default as index7HQ3XsFC8EMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyy]/[mm]/index.vue?macro=true";
import { default as indexLzfaNtJmhuMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/preview/index.vue?macro=true";
import { default as indexGxmFCByZyJMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/index.vue?macro=true";
import { default as index4HapbWBAImMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue?macro=true";
import { default as indexc8JTwvIDMfMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/index.vue?macro=true";
import { default as _91checkFileId_9373wuBd4BKZMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/select/[checkFileId].vue?macro=true";
import { default as indexSSlr1egeDOMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/select/index.vue?macro=true";
import { default as selectkeuNRyGdZXMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/operators/select.vue?macro=true";
import { default as index35QjiOdOg0Meta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/tickets/[ticketId]/index.vue?macro=true";
import { default as indexOB1RYb4celMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/tickets/index.vue?macro=true";
import { default as indexmbE4Ms4hplMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/index.vue?macro=true";
import { default as loginPP2LH6RKdtMeta } from "/home/runner/work/checkup/checkup/operator/src/pages/login.vue?macro=true";
export default [
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()",
    meta: indexb7OvZt53SXMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-timeboxes-timeboxId",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/timeboxes/:timeboxId()",
    meta: indexBbeoKfJI7DMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-timeboxes-timeboxId-workSheets-workSheetId",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/timeboxes/:timeboxId()/workSheets/:workSheetId()",
    meta: index1uZbuNYQseMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-todo",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/todo",
    meta: indexPjqSDR95cYMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/todo/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-tickets-ticketId",
    path: "/:organizationId()/checkFiles/:checkFileId()/tickets/:ticketId()",
    meta: indexy3B7uWtsqtMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/[ticketId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-tickets",
    path: "/:organizationId()/checkFiles/:checkFileId()/tickets",
    meta: index1sHnDK8g1NMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyy-mm",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyy()/:mm()",
    meta: index7HQ3XsFC8EMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyy]/[mm]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-preview",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/preview",
    meta: indexLzfaNtJmhuMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/preview/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes-timeboxId",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes/:timeboxId()",
    meta: indexGxmFCByZyJMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes-timeboxId-workSheets-workSheetId",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes/:timeboxId()/workSheets/:workSheetId()",
    meta: index4HapbWBAImMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes",
    meta: indexc8JTwvIDMfMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-select-checkFileId",
    path: "/:organizationId()/checkFiles/select/:checkFileId()",
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/select/[checkFileId].vue").then(m => m.default || m)
  },
  {
    name: "organizationId-checkFiles-select",
    path: "/:organizationId()/checkFiles/select",
    meta: indexSSlr1egeDOMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/checkFiles/select/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-operators-select",
    path: "/:organizationId()/operators/select",
    meta: selectkeuNRyGdZXMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/operators/select.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-tickets-ticketId",
    path: "/:organizationId()/tickets/:ticketId()",
    meta: index35QjiOdOg0Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/tickets/[ticketId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-tickets",
    path: "/:organizationId()/tickets",
    meta: indexOB1RYb4celMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/[organizationId]/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/checkup/checkup/operator/src/pages/login.vue").then(m => m.default || m)
  }
]