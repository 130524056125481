const withLoading = (isActive: Ref<boolean>) => {
  return async <T>(fn: () => Promise<T>) => {
    isActive.value = true
    // 処理が速すぎると画面がチラついて違和感なので最低200msは表示している
    const res = await Promise.all([
      fn(),
      new Promise((resolve) => setTimeout(resolve, 200)),
    ]).finally(() => {
      isActive.value = false
    })

    return res[0]
  }
}

export const useLoading = () => {
  const isActive = useState(() => false)

  return {
    isActive,
    withLoading: withLoading(isActive),
  }
}
