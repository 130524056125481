import {
  getLocationAccountEmail,
  getLocationAccountById,
} from '~/repositories/locationAccountRepository'

export const useAuth = () => {
  const signIn = async (
    organizationName: string,
    accountName: string,
    password: string
  ): Promise<{ error: any }> => {
    const organizationNameValue = organizationName.trim()
    const accountNameValue = accountName.trim()

    const supabaseClient = useSupabaseClient()

    const email = await getLocationAccountEmail(organizationNameValue, accountNameValue)
    if (!email) {
      return { error: new Error('No data returned') }
    }

    // 認証処理
    const {
      data: { user: supabaseUser },
      error: loginError,
    } = await supabaseClient.auth.signInWithPassword({
      email,
      password,
    })
    if (loginError || !supabaseUser) return { error: loginError || new Error('No data returned') }

    // 新規ログインsessionが発行されているため、sessionStorageを保険的に初期化
    const { clear } = useSessionStorage()
    clear()

    // stateに登録
    const { currentLocationAccount } = useCurrentLocationAccount()
    const locationAccount = await getLocationAccountById(supabaseUser.id)
    currentLocationAccount.value = locationAccount

    return { error: null }
  }

  const signOut = async () => {
    // supabaseのログアウト
    const supabaseClient = useSupabaseClient()
    // Operatorは複数端末、複数人での同一アカウント利用が前提のためsignOutはlocal scopeにしておく
    // https://supabase.com/docs/reference/javascript/auth-signout
    await supabaseClient.auth.signOut({ scope: 'local' })

    // useState周辺を破棄
    const { currentLocationAccount } = useCurrentLocationAccount()
    currentLocationAccount.value = null

    const { currentOperator } = useCurrentOperator()
    currentOperator.value = null

    // session Storageを破棄
    const { clear } = useSessionStorage()
    clear()
  }

  const recheckSession = async () => {
    // localStorageのsessionは有効期間内でもDB上のsessionが消されている場合、利用できない機能（auth)があるため
    // ログイン画面に飛ばしたい
    // そのため、auth.getUser()を通じてsessionが有効かどうかを確認してsignOut(localStorageのトークンを削除)する
    const { error } = await useSupabaseClient().auth.getUser()
    if (error && error.status === 403) await signOut()
  }

  return {
    signIn,
    signOut,
    recheckSession,
  }
}
