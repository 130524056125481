import { useI18n, type ComposerTranslation } from 'vue-i18n'
import type { CheckFile } from '~/entities/checkFile'
import type { CheckFileLabel } from '~/entities/checkFileLabel'
import type { DailyWorkFile } from '~/entities/dailyWorkFile'
import { getCheckFiles, getCheckFile } from '~/repositories/checkFileRepository'
import { getDailyWorkFilesByCheckFileIds } from '~/repositories/dailyWorkFileRepository'

export type CheckFileWithDailyWorkFileStatus = CheckFile & {
  dailyWorkFileStatus: DailyWorkFile['status'] | null
  dailyWorkFileStatusLabel: string | null
}

const getCheckFilesFunc = (t: ComposerTranslation) => {
  return async (
    locationId: number,
    checkFileLabelIds: CheckFileLabel['id'][],
    targetDate: Dayjs
  ): Promise<CheckFileWithDailyWorkFileStatus[]> => {
    const files = await getCheckFiles(locationId, checkFileLabelIds)
    const ids = files.map((file) => file.id)
    const workFiles = await getDailyWorkFilesByCheckFileIds(ids, targetDate)
    return files.map((file) => {
      const workFile = workFiles.find((workFile) => workFile.check_file_id === file.id)
      return {
        ...file,
        dailyWorkFileStatus: workFile ? workFile.status : null,
        dailyWorkFileStatusLabel: workFile
          ? translateDailyWorkFileStatusFunc(t)(workFile.status)
          : null,
      }
    })
  }
}

const translateDailyWorkFileStatusFunc = (t: ComposerTranslation) => {
  return (status: DailyWorkFile['status']) => {
    switch (status) {
      case 'WIP':
        return t('実施中')
      case 'SUBMITTED':
        return t('提出済')
      default:
        return ''
    }
  }
}

export const useCheckFile = () => {
  const { t } = useI18n()

  return {
    getCheckFiles: getCheckFilesFunc(t),
    getCheckFile,
  }
}

export const useFetchCheckFile = (checkFileId: Ref<CheckFile['id']>) => {
  const { data: checkFile, error } = useAsyncData(
    async () => await getCheckFile(checkFileId.value),
    {
      watch: [checkFileId],
    }
  )
  return { checkFile, error }
}
